.auth{
    .wrapper{
        width: 100%;
        display: flex;
        min-height: 100vh;
        .left-div{
            background-color: #8EBAC5;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
                @media (max-width: 1200px){
                display: none;
                }
            .logo-img{
                margin: 7% 0px 0px 12%;
                width: 250px;
            }
            .bottom-img{
                width: 100%;
            }
        }
        .right-div{
            position: relative;
            flex:1.5;
            display: flex;
            justify-content: center;
            align-items: center;
            .form-div{
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                width: 607px;
                gap: 32px;
                @media (max-width: 720px){
                    width: 90%;
                    margin: auto;
                    gap: 18px;
                }
                @media (max-width: 670px){
                    margin-top: 20%;
                }
                @media (max-width: 470px){
                    margin-top: auto;
                }

                .title-div{
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    margin-bottom: 24px;
                    @media (max-width: 670px){
                        gap: 12px;
                        margin-bottom: 16px;
                    }
                    .title{
                        color: #122146;
                        font-weight: 700;
                        font-size: 32px;
                        @media (max-width: 450px){
                            font-size: 28px;
                        }
                    }
                    .slogan{
                        color: #12214697;
                        font-weight:400;
                        font-size: 16px;
                    }
                }
                .inputs{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 24px;
                    justify-content: space-between;
                    @media (max-width: 670px){
                        gap: 12px;
                    }
                    .input-div{
                        @media (max-width: 670px){
                            input{
                            height: auto;
                            padding: 16px 10px;}
                        }
                   .mui-regiter-paasword{
                    padding-right: 10px;
                    border-radius: 12px;
                    border: 1px solid #E2E8F0;   
                    input{
                      border: none;
                    }
                   }
                }
                    .short{
                        width: 48%;
                        @media (max-width: 670px){
                            width: 100%;
                        }
                    }
                }
                .submit-div{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 16px;
                    text-align: center;
                    .submit-bttn{
                        cursor: pointer;
                        border: none;
                        background-color: #1C7CC4;
                        height: 56px;
                        width: 100%;
                        border-radius: 8px;
                        color: #FFFFFF;
                        font-size: 16px;
                        font-weight: 600;
                        &:hover{
                            background-color: #00588F;
                        }
                        &:disabled{
                            cursor: not-allowed;
                            pointer-events: none;
                            background-color: #1c7bc46f;
                        }
                    }
                    span{
                        color: #1D273B;
                        font-weight: 400;
                        font-size: 16px;
                        a{
                            margin-left: 10px;
                            text-decoration: none;
                            color: #1C7CC4;
                            font-weight: 600;
                            font-size: 16px;
                        }
                    }
                }
                
            }
            .home-link{
                display: flex;
                align-items: center;
                gap: 16px;
                text-decoration: none;
                position: absolute;
                top: 7%;
                left: 4%;
                color: #122146;
                font-weight: 600;
                font-size: 16px;
                @media (max-width: 670px){
                    top: 5%;
                }
                @media (max-width: 470px){
                    top: 3%;
                }
            }
        }
    }
}