.table-wrapper{
    overflow: auto;
    position: relative;
    border-radius: 12px;
    table{
    border-radius: 12px;
    background-color: #ffff;
    width: 100%;
    border-collapse:collapse;
    thead{
            height: 30px;
            top: 0;
            position: sticky;
            background-color: #F8FAFC;
    }
    .Cart{
        background: rgba(29, 37, 44, 0.03);
        @media (max-width: 968px){
            display: none;
        }
    }
    th{
        cursor: pointer;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        color: #000000CC;
        &:first-child{
            border-top-left-radius: 12px;
            padding-left: 40px;
        }
        &:last-child{
            border-top-right-radius: 12px;
        }

    }
    tbody{
        tr{
            padding-top: 10px;
            height: 72px;
        }
        .Cart-row{
            height: 76px;
            border-bottom: 1px solid #1D252C1A;
            @media (max-width: 1150px){
                height: auto;
            }
            @media (max-width: 968px){
                #ProDescription, #ProSize, #ProColor, #ProShipping{
                display: none;
            }
            }
            @media (max-width: 600px){
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                #ProPrices{
                    display: flex;
                    flex-direction: column;
                }
            }
            @media (max-width: 520px){
                #ProPrices span{
                font-size: 12px;
            }}
            &:last-child{
                border-bottom: none;
                @media (max-width: 1150px){
                    padding: 10px;
                }
            }
        }
    }
  td{
    font-size: 14px;
    font-weight: 400;
    color: #000000CC;

    &:first-child{
        padding-left: 40px;
        @media (max-width: 560px){
            padding-left: 10px;
        }
    }
  }
  .attr{
    font-weight: 500;
    color: black;
  }
  .ship{
    color: #0CB9B1;
  }
  .quantity-box{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 112px;
    height: 40px;
    background-color: #1D252C0D;
    border-radius: 4px;
    @media (max-width: 560px){
        height: 28px;
        width: 86px;
        padding: 4px;
    }
    .quantity{
        color: var(--dark, #122146);
        font-size: 14px;
        font-weight: 600;
    }
    button{
        width: 32px;
        height: 32px;
        border: none;
        border-radius: 4px;
        background: var(--white, #FFF);
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
        cursor: pointer;
        @media (max-width: 520px){
            width: 26px;
            height: 26px;
        }
    }
    .decrement-button{
    position: absolute;
    top:4px;
    left: 4px;
    }
    .increment-button{
        position: absolute;
        top:4px;
        right: 4px;
        }
}
.old{
    text-decoration: line-through;
    font-size: 14px;
    font-weight: 400;
    color:#5F7586;
    margin-right: 8px;
}
.new{
    font-size: 14px;
    font-weight: 500;
    color: #000000CC;
}
.remove-cartItem{
    color: #F93549;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    cursor: pointer;
    @media (max-width: 520px){
        font-size: 12px;
    }
}
  .status{
    font-size: 12px ;
    font-weight: 400;
    padding: 2px 7px;
    border-radius: 4px;
width: fit-content;
    &.Expired{
        background-color: #F935491A;
        color: #F93549;
    }
    &.Ready{
        color: #1C7CC4;
        background-color: #008FEB1A;
    }
    &.Completed{
        color: #00C34E;
        background-color: #00C34E1A;
    }
    &.Cancelled{
        background-color: #F935491A;
        color: #F93549;
    }
    &.Shipped{
        color: #1C7CC4;
        background-color: #008FEB1A;
    }
    &.Delivered{
        color: #00C34E;
        background-color: #00C34E1A;
    }
}
  .product-identity{
    display: flex;
    align-items: center;
    @media (max-width: 1150px){
        padding: 10px;
    }
    @media (max-width: 560px){
        padding: 4px;
    }
    img{
    width: 56px;
    height: 56px;
    @media (max-width: 450px){
        width: 42px;
        height: 42px;
    }
}

.abandoned-pro-title{
    gap: 8px;
    display: flex;
    flex-direction: column;
    p{
        max-width: 220px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        font-size: 14px;
        color: #000000CC;
        font-weight: 400;
        @media (max-width: 520px){
            font-size: 12px;
        }
    }
    span{
        max-width: 186px;
        padding: 0px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        background-color: #E5F4FD;
        border-radius: 35px;
        font-weight: 400;
        font-size: 14px;
        color: #122146;
        @media (max-width: 968px){
            display: none;
        }
    }
}
    }
    .abandoned-addtocart{
        padding-left: 0px;
        background-color: transparent;
        border: none;
        color: #1C7CC4;
        font-weight: 600;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
    }
    }
    .pagination-wrapper{
        padding: 24px 32px 24px 32px;
        display: flex;
        justify-content: flex-end;
        .MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
            background-color: #1C7CC4;
            color: #ffff;
            &:hover{
                background-color: #1c7bc4b5;
            }
        }
    }
   
}