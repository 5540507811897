p, h2, h3, h4{
    margin: 0;
    padding: 0;
}
.text_block p{
    color: #262626;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.text_block h2, .heading-one{
    color: var(--h1, #333);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.84px;
}
.text_block h3 {
    color: var(--Primary-Blue, #0A1F8F);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
#heading-two{
    color: var(--Primary-Blue, #0A1F8F);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.Privacy_banner_section_wrapper{
    background-color: #FFFFFF;
    max-width: 1440px;
    margin: auto;
}

.Privacy_banner_section{
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    padding: 90px 40px;
}

.Privacy_text_wrapper{
    display: flex;
    flex-direction: column;
    padding: 0px 96px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding-bottom: 40px;
    max-width: 1440px;
    margin: auto;

}

.text_block{
    margin: 40px auto 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    max-width: 1248px;
}

#blue_before{
    color: #717286;
    font-size: 20px;
    font-weight: 500;
    border-left: 3px solid var(--blue, #1C7CC4);
    padding-left: 20px;
}

hr.solid{
    width: 80%;
    border-bottom: 2px solid rgb(0, 0, 0);
}


@media (max-width: 1278px){
    .Privacy_banner_section_wrapper .Privacy_banner_section img{
        width: 100%;
    }
    
    .Privacy_banner_section{
        padding: 40px;
    }
}

@media (max-width: 786px){
    .Privacy_banner_section{
        padding: 0;
    }
    .Privacy_text_wrapper {
        padding: 0px 48px;
        padding-bottom: 30px;
    }
    .text_block h2{
        font-size: 21px;
    }
}