.banner {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 48px;
  height: 58px;
  background-color: #e5f4fd;
}
.banner-left-wrap {
  display: flex;
  align-items: center;
  gap: 16px;
}
.description {
  color: var(--dark-blue, #00588f);

  /* 16 semibold */
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.banner-icon {
  height: 24px;
  width: 24px;
}
.banner-right-wrap {
  display: flex;
  gap: 24px;
}
.new-link-banner {
  color: #323232;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  transition: 300ms;
}
.new-link-banner:hover {
  color: #0068a8;
}
.cta {
  width: 100%;
  max-width: 1344px;
  gap: 16px;
  display: flex;
  justify-content: space-between;
}
.covid-link {
  white-space: nowrap;
  text-decoration: underline;
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #0068a8;
  transition: 300ms;
}
.covid-link:hover {
  color: #323232;
}
.banner-text {
  font-size: 16px;
  font-family: 400;
}

@media screen and (max-width: 580px) {
  .banner {
    min-height: 50px;
    padding: 4px 24px;
  }
  .banner-left-wrap {
    gap: 3px;
  }
  .banner-right-wrap {
    display: none;
  }
  .cta {
    gap: 12px;
    align-items: center;
    flex-direction: column;
  }
  .banner-text {
    font-size: 10px;
    font-weight: 500;
  }
  .cta a {
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    color: #0068A8;
  }
}
/* @media screen and (max-width: 400px) {
    .cta{
        gap: 4px;
      flex-direction: column;
    }
    .banner{
        padding: 5px 0px;
        height: auto;
    }
  } */
