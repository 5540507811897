.cart {
  .cart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 20px;
    background-color: #f8fafc;

    @media (max-width: 720px) {
      background-color: white;
      padding: 0;
    }

    .cart-details {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 1248px;
      gap: 48px;

      .cart-main-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .current-path-link {
          align-items: center;
          justify-content: space-between;
          max-width: 1280px;
          display: flex;
          width: 100%;

          @media (max-width: 960px) {
            flex-direction: column;
            gap: 20px;
          }

          .rightsection {
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;

            @media (max-width: 720px) {
              flex-direction: column;
            }
          }

          .links {
            display: flex;
            align-items: center;
            gap: 12px;

            @media (max-width: 720px) {
              width: 100%;
              background-color: #e5f4fd;
              padding: 24px 0;
              justify-content: center;
              align-items: center;
            }

            a {
              text-decoration: none;
              font-size: 16px;
              font-weight: 600;
              color: #5f7586;

              @media (max-width: 720px) {
                font-size: 14px;
              }

              &:last-child {
                color: #00588f;
              }
            }
          }

          .shipping-info {
            background-color: #e5f4fd;
            width: 420px;
            height: 56px;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            @media (max-width: 720px) {
              width: auto;
              height: auto;
              padding: 12px 20px;
              margin: 8px;
            }

            h1 {
              font-weight: 400;
              font-size: 14px;
              color: #1c7cc4;
            }
          }

          .teaser {
            display: flex;
            margin: 8px;
            padding: 12px 20px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 12px;
            background: var(--ligh-red, #feebed);

            span {
              color: var(--red, #f93549);

              font-family: "Poppins", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }

        .cart-summary {
          border: 1px solid #1d252c1a;
          border-radius: 8px;
        }
      }

      .coupon-div {
        height: 84px;
        border-radius: 12px;
        background: var(--ligh-blue, #e5f4fd);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 24px;

        @media (max-width: 1028px) {
          flex-direction: column;
          padding: 40px 0;
          height: auto;
          gap: 16px;
        }

        @media (max-width: 480px) {
          padding: 40px 18px;
        }

        .coupon-input {
          display: flex;
          gap: 12px;

          @media (max-width: 1028px) {
            width: auto;
            min-width: 70%;
          }

          input {
            width: 377px;
            height: 52px;
            border-radius: 12px;
            border: 1px solid #1d252c1a;
            padding: 0px 17px;

            @media (max-width: 1028px) {
              width: 70%;
              height: auto;
              padding: 16px 28px;
            }

            @media (max-width: 480px) {
              padding: 10px 18px;
            }

            &:focus {
              outline: none;
            }

            &::placeholder {
              color: #94a3b8;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .apply-coupon {
            background-color: #cce9fb;
            width: 179px;
            height: 52px;
            border-radius: 8px;
            border: none;
            color: #0068a8;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;

            @media (max-width: 1028px) {
              width: 30%;
              height: auto;
              padding: 16px 28px;
            }

            @media (max-width: 480px) {
              padding: 10px 18px;
              font-size: 14px;
            }
          }
        }

        .applied-coupon {
          display: flex;
          align-items: center;
          background-color: #e5f4fd;
          padding: 10px;
          border-radius: 8px;
          margin-left: 20px;

          .coupon-code {
            color: #1c7cc4;
            font-weight: 600;
            margin-right: 10px;
          }

          .remove-coupon {
            background: none;
            border: none;
            color: #f93549;
            cursor: pointer;
            font-size: 20px;
          }
        }

        .checkout-button {
          text-decoration: none;
          cursor: pointer;
          width: 316px;
          background-color: #1c7cc4;
          height: 52px;
          border: none;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          gap: 16px;
          align-items: center;
          color: #ffff;
          font-size: 16px;
          font-weight: 600;

          @media (max-width: 1028px) {
            width: 80%;
            min-width: 70%;
            padding: 16px 28px;
            height: auto;
          }

          @media (max-width: 480px) {
            padding: 10px 18px;
            font-size: 14px;
          }

          .line {
            border: 1px solid #ffffff;
            height: 20px;
          }

          .total {
            font-weight: 400;
            font-size: 20px;
          }
        }
      }
    }

    .empty-cart {
      width: 100%;
      max-width: 1248px;
      background-color: #ffff;
      height: 300px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      align-items: center;

      @media (max-width: 480px) {
        width: 85%;
        height: auto;
        padding: 30px 0px;
        text-align: center;
      }

      img {
        width: 96px;
        height: 96px;

        @media (max-width: 480px) {
          width: 72px;
          height: 72px;
        }
      }

      .text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .textOne {
          color: #122146;
          font-size: 20px;
          font-weight: 400;
        }

        .slogan {
          color: #5f7586;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .shop-button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        background-color: #1c7cc4;
        width: 152px;
        height: 52px;
        border-radius: 8px;
        color: #ffff;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
