.Mobile_Navbar {
  position: relative;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
  height: 84px;
  font-family: "Poppins", sans-serif;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 8px 22px 8px 22px;
}

.dropdown-links-wrapper {
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  height: calc(100vh - 212px);
  display: flex;
  flex-direction: column;
  z-index: 99;
  background: white;
  padding: 32px;
  margin-top: 25px;
  align-items: flex-start;
}

.dropdown-links-wrapper a {
  font-weight: 600;
  font-size: 18px;
  color: #323232;
  text-decoration: none;
  display: block;
  padding-bottom: 32px;
}

.dropdown-links-wrapper .drop-down-link {
  font-weight: 600;
  font-size: 18px;
  color: #323232;
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding-bottom: 32px;
}
.imglogo-navbar-mobile{
width: 55%;
min-width: 147px;
max-width: 250px;
}
.logo-mobile {
  width: 100%;
}
.cart-bttn-mobile {
  font-size: 30px;
  background-color: transparent;
  color: #1c7cc4;
  padding: 8px 8px 4px 8px;
  height: fit-content;
  border: 1.4px solid #1c7cc4;
  border-radius: 8px;
  position: relative;
  
}
.cart-bttn-mobile .cartCount {
  position: absolute;
}

@media (max-width: 1268px) {
  .Mobile_Navbar {
    display: flex;
  }
}

@media (max-width: 367px) {
  .cart-bttn-mobile {
    padding: 6px 6px 2px 6px;
    font-size: 28px;
  }
}

.MobDrop {
  display: flex;
  flex-direction: column;
}

.supmenuparent {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.Mobbox {
  padding: 10px;
}
