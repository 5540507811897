.Navbar {
  position: relative;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
  height: 84px;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 48px 8px 48px;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1344px;
}
.left-box {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
}
.drop-down-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #323232;
  font-weight: 600;
  font-size: 14px;
  position: relative;
}
.drop-down-link:hover {
  color: #0068a8;
}
.drop-menu {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  width: 700px;
  height: 323px;
  z-index: 20;
  top: calc(100% + 45px);
  left: 0;
  padding: 24px;
  border-radius: 8px;
  position: absolute;
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
}
.cartCount {
  min-width: 14px;
  min-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: #f93549;
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  right: 4px;
  color: #ffff;
  font-size: 14px;
  font-weight: 600;
}
.drop-menu.two {
  height: 210px;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 342px;
}
.drop-menu.two .menu-box {
  width: calc(100% - 48px);
  height: 97px;
}
.drop-menu .menu-box {
  transition: 0.2s all ease-in-out;
  display: flex;
  text-decoration: none;
  border-radius: 8px;
  align-items: center;
  gap: 16px;
  padding: 0px 24px;
  width: calc(49.5% - 48px);
}
.menu-box:hover {
  background-color: #e5f4fd;
}
.menu-box:hover .icon-div {
  background-color: #ffffff;
}
.logo-img-wrapper-navbar-main{
  max-width: 265px;
  min-width: 250px;
  width: 50%;
  max-height: 56px;
}
.logo-img {
 width: 100%;
}
.icon-div {
  transition: 0.2s all ease-in-out;
  width: 40px;
  height: 40px;
  background-color: #e5f4fd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 56px);
}
.menuitemtitle {
  color: #000000cc;
  font-weight: 600;
  font-size: 16px;
}
.menuitempara {
  text-wrap: wrap;
  white-space: wrap;
  color: #000000cc;
  font-size: 14px;
  font-weight: 400;
}
.links-wrapper {
  gap: 1.5vw;
  display: flex;
  align-items: flex-start;
}
.links-wrapper a {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  color: #323232;
  text-decoration: none;
  display: block;
  transition: 300ms;
}
.links-wrapper a:hover {
  color: #0068a8;
}
.links-wrapper .drop-down-link {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 300ms;
}
.right-box {
  gap: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 30%;
}
.side-link {
  white-space: nowrap;
  font-size: 14px;
  color: #323232;
  text-decoration: none;
  display: block;
  font-weight: 400;
}
.bttn-wrapper {
  display: flex;
  gap: 12px;
}
.cart-bttn {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  font-size: 30px;
  color: #1c7cc4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 1px solid #1d252c1a;
  border-radius: 8px;
  transition: 300ms;
}
.cart-bttn.count {
  border: 1px solid #1d252c1a;
  color: #1c7cc4;
}
.cart-bttn:hover {
  border: 1.4px solid #1c7cc4;
}
.shop-bttn {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106px;
  height: 56px;
  background-color: #1c7cc4;
  color: #fff;
  border-radius: 8px;
  transition: 300ms;
}
.shop-bttn:hover {
  background-color: #00588f;
}

@media (max-width: 1268px) {
  /* .Navbar {
    display: none;
  } */
  .right-box {
    gap: 14px;
  }
}
