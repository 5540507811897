.checkout-complete{
    .checkout-complete-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F8FAFC;
        padding: 80px 20px;
        @media (max-width: 700px){
            padding: 30px 10px;
        }
        .checkout-complete-box{
           background-color: #ffff;
           border-radius: 12px;
           padding: 32px 0px;
           width: 100%;
           max-width: 1248px;
           display: flex;
           justify-content: center;
           align-items: center;
           flex-direction: column;
           gap: 24px;
           .text-div{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media (max-width: 700px){
                text-align: center;
            }
            .title{
                color: #122146;
                font-size: 20px;
                font-weight: 400;
            }
            .text{
                display: block;
                text-align: center;
                color: #5F7586;
                font-weight: 400;
                font-size: 16px;
                max-width: 610px;
            }
           }
           .toDashboard{
            width: 202px;
            height: 52px;
            background-color: #1C7CC4;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            text-decoration: none;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 600;
            @media (max-width: 700px){
                width: 100%;
            }
        }
        }
    }
}