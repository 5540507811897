.whishlist {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.381);
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    background-color: #ffff;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    height: 90%;
    max-height: 480px;
    .top-div {
      display: flex;
      width: calc(100% - 48px);
      border-bottom: 1px solid #1d252c1a;
      height: 32px;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .Closei {
        transition: 300ms;
        &:hover {
          scale: 1.2;
          color: #000000 !important;
        }
      }
      .top-div-right-wrap {
        @media (max-width: 400px) {
          flex-direction: column;
        }
      }
      p {
        color: #122146;
        font-weight: 600;
        font-size: 20px;
        margin-right: 8px;
      }
      .badge {
        display: flex;
        width: fit-content;
        padding: 0px 16px;
        align-items: center;
        justify-content: space-between;
        background-color: #1d252c1a;
        border-radius: 32px;
        @media (max-width: 968px) {
          padding: 0px 10px;
        }
        .count {
          color: #000000cc;
          font-weight: 600;
          font-size: 16px;
        }
        .total {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #5f7586;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .scrollable-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      height: calc(100% - 184px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #1c7cc4;
      }
      .product-box-wish {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        padding: 24px;
        @media (max-width: 968px) {
          flex-wrap: wrap;
          height: auto;
          gap: 24px;
          padding: 16px;
        }
        &.blue {
          background-color: #f8fafc;
          .add-to-cart-button {
            background-color: #1c7cc4;
            color: #ffff;
            &:hover {
              background-color: #e5f4fd;
              color: #1c7cc4;
            }
          }
        }
        .left {
          display: flex;
          .img-div {
            width: 113px;
            height: 113px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .item-desc {
            width: 186px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .category {
              color: #00000080;
              font-weight: 400;
              font-size: 12px;
            }
            .name {
              color: #000000cc;
              font-weight: 600;
              font-size: 14px;
            }
            .item-q {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .counter-div {
                padding: 0px 8px;
                border: 1px solid #1d252c1a;
                border-radius: 26px;
                width: 71px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .count {
                  font-weight: 400;
                  font-size: 14px;
                  color: #122146;
                }
                .icon {
                  color: #122146;
                  font-size: 14px;
                  cursor: pointer;
                }
              }
              .price-item-w {
                color: #1c7cc4;
                font-size: 20px;
                font-weight: 700;
              }
            }
          }
        }
        .add-to-cart-button {
          background-color: #e5f4fd;
          border-radius: 8px;
          height: 56px;
          width: 130px;
          border: none;
          color: #1c7cc4;
          font-weight: 700;
          font-size: 16px;
          cursor: pointer;
          @media (max-width: 968px) {
            width: 100%;
          }
          &:hover {
            background-color: #1c7cc4;
            color: #ffff;
          }
        }
      }
    }
    .bottom-section {
      height: 56px;
      padding: 24px;
      width: calc(100% - 48px);
      border-top: 1px solid #1d252c1a;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .shopping {
        color: #5f7586;
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #1d252c1a;
        height: 56px;
        width: 222px;
        transition: 300ms;
        &:hover {
          background-color: #00588f;
          color: #e5f4fd;
        }
      }
    }
  }
}
