.passsword-changed{
    width: 614px;
    display: flex;
    gap: 32px;
    flex-direction: column;
    .top-wrapper{
        display: flex;
        flex-direction: column;
        gap: 48px;
        .title-div{
            display: flex;
            flex-direction: column;
            gap: 24px;
            .title{
            font-size: 32px;
            font-weight: 700;
            color: #122146;
            }
            .status{
                gap: 7px;
                display: flex;
                color: #00588F;
                font-weight: 700;
                font-size: 16px;
            }
        }
        .text{
            color: #1221469c;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .bottom-wrapper{
        display: flex;
        flex-direction: column;
        .resent{
            margin-top: 8px;
            height: 56px;
            background-color: #1C7CC4;
            border-radius: 8px;
            border: none;
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
            cursor: pointer;
        }
        a{
            margin-top: 24px;
            display: block;
            text-align: center;
            color: #1C7CC4;
font-weight: 600;
font-size: 16px;
text-decoration: none;
        }
    }
   
}