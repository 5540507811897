.MiniCart {
  visibility: hidden;
  opacity: 0;
  height: 100vh;
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.461);
  display: flex;
  justify-content: flex-end;
  transition: all 0.3s ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
  }
  .mini-cart-container {
    width: 372px;
    padding-bottom: 24px;
    background-color: #ffffff;
    height: 100%;
    transform: translateX(100%);
    transition: all 0.5s ease-in-out;
    &.show {
      transform: translateX(0%);
    }
    .top {
      background-color: #f8fafc;
      height: 78px;
      border-bottom: 1px solid #1d252c1a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 24px 0px 24px;
      .left {
        display: flex;
        gap: 10px;
        align-items: center;
        .cart-title {
          color: #122146;
          font-weight: 600;
          font-size: 20px;
        }
        a {
          color: #0068a8;
          font-weight: 600;
          font-size: 16px;
          transition: 300ms;
          &:hover {
            color: #122146;
          }
        }
      }
      .close {
        cursor: pointer;
        transition: 300ms;
        &:hover {
          scale: 1.2;
        }
      }
    }
    .ItemsWrapper {
      height: calc(100% - 202px);
      overflow: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      .item-box {
        display: flex;
        padding: 24px;
        border-bottom: 1px solid #1d252c1a;
        &:last-child {
          border-bottom: none;
        }
        .img-box {
          width: 113px;
          img {
            max-height: 113px;
            width: 100%;
          }
        }
        .right-div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: calc(100% - 113px);
          .top-item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .topItemstext {
              color: #00000080;
              font-size: 12px;
              font-weight: 400;
              text-transform: none;
            }
            span {
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 600;
              color: #000000cc;
            }
          }
          .description-box {
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            .quantity-box {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              width: 112px;
              height: 40px;
              background-color: #1d252c0d;
              border-radius: 4px;
              .quantity {
                color: var(--dark, #122146);
                font-size: 14px;
                font-weight: 600;
              }
              button {
                width: 32px;
                height: 32px;
                border: none;
                border-radius: 4px;
                background: var(--white, #fff);
                box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
                cursor: pointer;
              }
              .decrement-button {
                position: absolute;
                top: 4px;
                left: 4px;
              }
              .increment-button {
                position: absolute;
                top: 4px;
                right: 4px;
              }
            }
            .price-text {
              color: #1c7cc4;
              font-size: 20px;
              font-weight: 700;
            }
          }
        }
      }
    }
    .bottom-minicart {
      padding: 0px 24px;
      height: 100px;
      border-top: 1px solid #1d252c1a;
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
      .totalcart-div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .total-items {
          color: #000000cc;
          font-size: 16px;
          font-weight: 600;
        }
        .total-price {
          color: #1c7cc4;
          font-weight: 700;
          font-size: 24px;
        }
      }
      .buy-button {
        width: 133px;
        height: 56px;
        background-color: #1c7cc4;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        transition: 300ms;
        &:hover {
          background-color: #00588f;
        }
      }
    }
  }
}
