
.input-div{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-bottom: 24px;
  
   
    label{
        font-weight: 600;
        font-size: 16px;
        color: #0F172A;
    }
    input{
        height: 27px;
        color: #122146;
        font-size: 16px;
        border-radius: 12px;
        border: 1px solid #1D252C1A;
        padding: 14.5px 17px 14.5px 17px;
        &:focus{
            outline: 1px solid #00588F;
            box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10);
        }
        &::placeholder{
            color: #94A3B8;
            font-weight: 400;
            font-size: 16px;
        }
    }
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        border-radius: 12px;
    }
}
.password-labels{
    display: flex;
    justify-content: space-between;
    a{
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        color: #1C7CC4;
        @media (max-width: 470px){
            font-size: 14px;
            font-weight: 500;
        }
    }
}