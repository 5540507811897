.Footer-Wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-top: 1.4px solid var(--stroke, rgba(29, 37, 44, 0.1));
}
.img-wrapper-footer{
  height: 56px;
  max-width: 265px;
  margin-bottom: 10px;
}
.img-wrapper-footer img{
  height: 100%;
  width: 100%;
}
.upper-footer {
  padding: 16px 96px;
}
.footer-header {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}
.boxes-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 1248px;
  margin: auto;
}

.box {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.boxes-wrapper .box .right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.box-head {
  color: rgba(0, 0, 0, 0.8);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0;
}

.box-desc {
  color: rgba(0, 0, 0, 0.8);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}

.middle-footer {
  border-top: 1.4px solid var(--stroke, rgba(29, 37, 44, 0.1));
  display: flex;
  flex-direction: row;
  gap: 130px;
  padding: 80px 96px;
  position: relative;
  max-width: 1248px;
  margin: auto;
}

.middle-footer .left-side {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.middle-footer .left-side form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.middle-footer .left-side form .submit {
  border-radius: 8px;
  background: var(--blue, #1c7cc4);
  border: none;
  padding: 10px 16px;
  transition: 300ms;
}
.middle-footer .left-side form .submit:hover {
  background-color: #00588f;
}
.middle-footer .left-side form .submit:disabled {
  background-color: #81878a;
  pointer-events: none;
}
.newsletter {
  padding: 16px 16px;
  border-radius: 8px;
  border: 1px solid var(--stroke, rgba(29, 37, 44, 0.1));
  background: var(--ligh-gray, #f8fafc);
  color: var(--gray, #5f7586);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  flex: 1 0 0;
  width: 300px;
}

.social-icons {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  gap: 21px;
}
.social-icons > a {
  transition: 300ms;
}
.social-icons > a:hover {
  scale: 1.2;
}

.social-icons img {
  width: 24px;
  height: 24px;
}

.middle-footer .right-side {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 40px;
}

.middle-footer .right-side div {
  z-index: 1;
}

.middle-footer .right-side div ul {
  list-style: none;
  padding: 0;
  margin-top: 14px;
}
.middle-footer .right-side div ul a {
  color: inherit;
  text-decoration: none;
  transition: 300ms;
}
.middle-footer .right-side div ul a:hover {
  color: #00588f;
}

.middle-footer .right-side div ul li {
  padding: 8px 0;
}

.bg-squares {
  position: absolute;
  bottom: 12%;
  right: 9%;
  z-index: 0;
}

.bottom-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--dark, #122146);
  padding: 0px 105px;
  height: 70px;
  max-width: 1248px;
  margin: auto;
}

.copy-rights {
  width: 50%;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
.copy-rights p {
  line-height: 20px;
}
.copy-rights p a {
  transition: 300ms;
}
.copy-rights p a:hover {
  color: #1c7cc4 !important;
}

.copy-rights span {
  color: #bac0c5;
}

.footer-menu {
  width: fit-content;
}

.footer-menu ul {
  gap: 9%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.footer-menu ul a {
  display: block;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  transition: 300ms;
}
.footer-menu ul a:hover {
  color: #0e76b7;
}

@media (max-width: 1278px) {
  .boxes-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }
  .middle-footer {
    padding: 30px 66px;
    gap: 20px;
  }
  .middle-footer .left-side {
    width: 40%;
  }
  .middle-footer .right-side {
    width: 60%;
  }
  .middle-footer .right-side {
    gap: 12px;
  }
  .newsletter {
    width: 120%;
  }
  .bottom-footer {
    padding: 0px 45px;
  }
}

@media (max-width: 768px) {
  .upper-footer {
    margin: auto;
    padding: 16px 46px;
  }
  .boxes-wrapper {
    display: flex;
    flex-direction: column;
  }
  .middle-footer {
    flex-direction: column;
    gap: 60px;
    padding: 30px 46px;
  }
  .middle-footer .left-side {
    width: 100%;
  }
  .middle-footer .right-side {
    width: 100%;
  }
  .bottom-footer {
    flex-direction: column;
    gap: 12px;
    padding: 20px 45px;
  }
  .copy-rights,
  .footer-menu {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .copy-rights {
    text-align: center;
  }
  .footer-menu ul {
    padding: 10px;
    margin: 0;
  }
  .footer-menu ul li {
    padding: 8px;
  }
  .bottom-footer {
    height: auto;
  }
}
@media (max-width: 600px) {
  .middle-footer .right-side {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }
  .middle-footer .right-side .services-menu {
    order: 3;
  }
  .footer-menu ul {
    padding: 0px;
  }
}
@media (max-width: 400px) {
  .footer-menu ul {
    flex-direction: column;
    padding: 6px;
    text-align: center;
  }
}
